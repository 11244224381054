import React, { useEffect, useState } from "react";
import { styles } from "../../styles/index.js";
import { CustomNavbar, Footer } from "../../components";
import './styles.css';
import { Pricing } from "./Pricing.tsx";
import { Features } from "./Features.tsx";
import { FAQs } from "./FAQs.tsx";
import { TrustedBy } from "./TrustedBy.tsx";

const HomeScreen = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);   

    useEffect(() => {
        const sections = document.querySelectorAll('.section');
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                }
            });
        }, {
            threshold: 0.2, // Trigger when 50% of the section is visible
        });
        
        // Observe each section
        sections.forEach(section => observer.observe(section));

        return () => {
            // Clean up observer on unmount
            observer.disconnect();
        };
    }, []);

    const handleJoinBetaClick = () => {
        setIsModalOpen(true);
    };

    // Close Modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleFormSubmit = () => {
        setIsFormSubmitted(true);
        setIsModalOpen(false);
    };

    return (
        <>
          <div className="font-inter antialiased bg-light bg-white text-black tracking-tight">
            <div className="flex flex-col min-h-screen overflow-hidden">
                <CustomNavbar />
                {/* Hero */}
                <section 
                    className="flex flex-col lg:flex-row items-center justify-between py-16 bg-gray-900 text-white"
                    style={styles.background}
                >
                    <div className="flex flex-col items-start lg:w-1/2 px-8 lg:px-16 mb-8 lg:mb-0 space-y-6">
                        <h1 className="text-4xl lg:text-5xl font-semibold leading-tight">
                            Built for Small Landlords & Growing Agencies
                        </h1>

                        <p className="text-lg lg:text-2xl">
                            Rentee is your partner for stress-free management, designed specifically for landlords and growing agencies.
                        </p>


                        {isFormSubmitted ? (
                            <div className="rounded-lg">
                                <h2 className="text-2xl font-semibold text-left mb-4"><span className="text-primary">Thank you</span> for Joining!</h2>
                                <p className="text-lg text-left mb-4">You have successfully joined our waiting list. We’ll notify you when we launch!</p>
                            </div>
                        ) :(
                            <button 
                                onClick={handleJoinBetaClick}
                                className="bg-teal-500 hover:bg-teal-600 text-white text-lg font-semibold py-4 px-12 rounded-full transition-colors duration-300"
                            >
                                Get Beta Access
                            </button>
                        )}

                        {/* SVG ticks below the button */}
                        <div className="mt-6 flex-col items-center justify-center">
                            <div className="flex items-center justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    className="mr-4 text-teal-500"
                                >
                                    <path
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        d="M9 18l4 4 8-8"
                                    />
                                </svg>
                                <p className="text-white">Manage properties effortlessly.</p>
                            </div>
                            <div className="flex items-center justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    className="mr-4 text-teal-500"
                                >
                                    <path
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        d="M9 18l4 4 8-8"
                                    />
                                </svg>
                                <p className="text-white">Built for growth and scalability.</p>
                            </div>
                            <div className="flex items-center justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    className="mr-4 text-teal-500"
                                >
                                    <path
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        d="M9 18l4 4 8-8"
                                    />
                                </svg>
                                <p className="text-white">Designed for small landlords.</p>
                            </div>
                        </div>


                    </div>
                    <div className="lg:w-1/2 flex items-center justify-center lg:justify-end px-8 lg:px-16">
                        {/* You can add an image, illustration, or leave it as a placeholder */}
                        <img 
                            src={require('../../assets/images/hero-image.png')} 
                            alt="Hero" 
                            className="w-156 lg:w-156 h-auto rounded-lg"
                        />
                    </div>
                </section>

                {/* Modal */}
                {isModalOpen && !isFormSubmitted && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                            <h2 className="text-2xl font-semibold text-center mb-4">Join the Waiting List</h2>
                            
                            {/* Netlify form */}
                            <form 
                                name="waitlist-form" 
                                method="POST" 
                                data-netlify="true" 
                                onSubmit={handleFormSubmit} 
                            >
                                <input 
                                    type="hidden" 
                                    name="form-name" 
                                    value="waitlist-form" 
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    required
                                    className="w-full py-3 px-4 text-lg text-teal-600 placeholder-gray-400 focus:outline-none border border-teal-300 rounded-lg mb-4"
                                />
                                <button
                                    type="submit"
                                    className="w-full bg-teal-500 text-white py-3 px-6 text-lg font-semibold rounded-lg transition-all duration-300 hover:bg-teal-600 focus:ring-2 focus:ring-teal-600"
                                >
                                    Join Now
                                </button>
                            </form>

                            <button
                                onClick={handleCloseModal}
                                className="absolute top-2 right-2 text-lg font-semibold text-gray-600"
                            >
                                <img 
                                    alt="close" 
                                    src={require('../../assets/images/close_icon.png')} 
                                    className="w-10"
                                />
                            </button>
                        </div>
                    </div>
                )}

                {/* What are we */}
                <section className="section bg-white py-24 pb-32">
                    <div className="max-w-7xl mx-auto px-6 lg:px-8">
                        <div className="text-center mb-10">
                            <h2 className="text-4xl md:text-5xl font-bold text-navy">Manage Properties with Ease, No Matter Your Size</h2>
                            <p className="mt-4 text-lg text-gray-600">Whether you're just starting or scaling up, our platform is built for you.</p>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {/* Card 1  */}
                            <div className="relative bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 p-6">
                                <div className="absolute -top-6 -left-6 w-24 h-24 bg-blue-500 rounded-full blur-xl opacity-30"></div>
                                <h3 className="text-2xl font-semibold text-gray-900 mb-4">For Small Portfolios</h3>
                                <p className="text-gray-700">Whether you have 1 or 20 properties, streamline your management without unnecessary complexity.</p>
                            </div>

                            {/* Card 2 */}
                            <div className="relative bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 p-6">
                                <div className="absolute -top-6 -right-6 w-24 h-24 bg-green-500 rounded-full blur-xl opacity-30"></div>
                                <h3 className="text-2xl font-semibold text-gray-900 mb-4">For Growing Agencies</h3>
                                <p className="text-gray-700">Manage up to 100 properties with ease. Perfect for agencies expanding their portfolios.</p>
                            </div>

                            {/*  Card 3 */}
                            <div className="relative bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 p-6">
                                <div className="absolute -bottom-6 -right-6 w-24 h-24 bg-purple-500 rounded-full blur-xl opacity-30"></div>
                                <h3 className="text-2xl font-semibold text-gray-900 mb-4">For Everyone in Growth</h3>
                                <p className="text-gray-700">No more paying for expensive software packed with features you don’t need. Get exactly what works for you.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Trust by */}
                <TrustedBy />

               {/* Steps */}
                <section 
                    id="steps"
                    className="section py-12 px-8 md:px-16 bg-white relative overflow-hidden"
                >
                    <div className="text-center mb-12">
                        <h1 className="text-4xl md:text-5xl text-navy font-extrabold mb-4">
                            <span className="text-teal-500">Get started with Rentee</span> in 3 Easy Steps
                        </h1>
                        <p className="text-lg md:text-xl text-gray-600">
                            Rentee is built to simplify property management for both <b>landlords</b> and <b>tenants</b>. 
                            Let’s get you started!
                        </p>
                    </div>
                    
                    {/* Flex container for steps and image */}
                    <div className="flex flex-col md:flex-row justify-between items-center w-full gap-16 md:gap-32">
                        
                        {/* Steps */}
                        <div className="flex flex-col gap-8 md:w-1/2">
                            
                            {/* Step 1 */}
                            <div className="flex items-center border-2 border-gray-100 shadow-lg rounded-xl p-8 hover:shadow-xl transition duration-300">
                                <h1 className="text-teal-500 text-5xl font-bold mr-6">1</h1>
                                <div>
                                    <h2 className="text-navy text-2xl font-semibold">Create Your Account</h2>
                                    <p className="text-lg text-gray-600">Tell us a bit about yourself. It’s quick and easy, just a few steps to get started!</p>
                                </div>
                            </div>

                            {/* Step 2 */}
                            <div className="flex items-center border-2 border-gray-100 shadow-lg rounded-xl p-8 hover:shadow-xl transition duration-300">
                                <h1 className="text-teal-500 text-5xl font-bold mr-6">2</h1>
                                <div>
                                    <h2 className="text-navy text-2xl font-semibold">Add Your Properties</h2>
                                    <p className="text-lg text-gray-600">Set up your properties. Add your property details to get things rolling.</p>
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="flex items-center border-2 border-gray-100 shadow-lg rounded-xl p-8 hover:shadow-xl transition duration-300">
                                <h1 className="text-teal-500 text-5xl font-bold mr-6">3</h1>
                                <div>
                                    <h2 className="text-navy text-2xl font-semibold">Invite Your Tenants</h2>
                                    <p className="text-lg text-gray-600">Share a unique tenant code and invite them to join the platform.</p>
                                </div>
                            </div>

                        </div>

                        {/* Image on the right of all steps */}
                        <div className="w-full md:w-1/2 mt-8 md:mt-0">
                            <img
                                src={require('../../assets/images/steps_image.png')} // Add your image path here
                                alt="stages"
                                className="w-full h-auto object-cover transform hover:scale-105 transition duration-300"
                            />
                        </div>
                    </div>
                </section>

                {/* Features */}
                <Features />

                {/* Beta CTA */}
                <section className="section py-12 px-8 md:px-16 bg-gray-50 relative  overflow-hidden">
                    <img
                        src={require('../../assets/images/beta_cta_bg.png')}
                        alt="Background"
                        className="absolute top-0 left-0 w-full h-full lg:object-cover object-cover object-center "
                    />
                    <div className="w-full relative px-8 md:px-16 text-center mt-12">
                        <p className="text-4xl font-bold text-center mb-6 text-navy">Want 3 Months Free?</p>
                        <p className="text-lg text-center mb-8 text-gray-600">Join our exclusive beta program and be among the first to explore the future of property management.</p>
                        <div className="text-center">
                            {isFormSubmitted ? (
                                <div className="rounded-lg bg-teal-50 p-6">
                                    <h2 className="text-2xl font-semibold text-left mb-4 text-teal-500">Thank you for joining!</h2>
                                    <p className="text-lg text-left mb-4 text-gray-600">You’re on the list! We’ll notify you once we launch.</p>
                                </div>
                            ) : (
                                <button 
                                    onClick={handleJoinBetaClick}
                                    className="bg-teal-500 hover:bg-teal-600 text-white text-lg font-semibold py-4 px-12 rounded-full transition duration-300 shadow-lg transform hover:scale-105"
                                >
                                    Join Our Beta Now
                                </button>
                            )}
                        </div>
                    </div>
                </section>

                {/* FAQ  */}
                <FAQs/>
                
                {/* Pricing */}
               <Pricing />

                {/* join beta */}
                <section
                    id="action_section"
                    className="section flex flex-col items-center py-16 bg-teal-500 relative h-auto"
                >
                    <div className="relative z-10 text-center px-6 md:px-0">
                        {/* Heading */}
                        <h2 className="text-5xl font-extrabold text-white mb-4">Join Our Beta</h2>
                        <p className="text-lg text-white mb-8">Sign up to get early access and exclusive updates.</p>

                        {isFormSubmitted ? (
                            <div>
                                <h2 className="text-2xl font-bold text-left mb-4 text-white">Thank you for Joining!</h2>
                                <p className="text-lg text-left mb-4 text-white ">You have successfully joined our waiting list. We’ll notify you when we launch!</p>
                            </div>
                        ) : (
                            <form
                                name="waitlist-form" 
                                method="POST" 
                                data-netlify="true" 
                                onSubmit={handleFormSubmit} 
                            >
                                {/* Hidden field for Netlify */}
                                <input type="hidden" name="form-name" value="beta-form" />

                                {/* Form Fields */}
                                <div className="flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden p-6">
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        placeholder="Enter your email"
                                        className="w-full py-4 px-6 mb-4 text-lg text-teal-600 placeholder-gray-400 focus:outline-none border border-teal-300 rounded-lg focus:ring-2 focus:ring-teal-500"
                                    />
                                    <button
                                        type="submit"
                                        className="w-full bg-navy text-white py-4 px-6 text-lg font-semibold rounded-full transition-all duration-300 hover:bg-teal-500 focus:ring-2 focus:ring-teal-600"
                                    >
                                        Join the waiting list
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </section>

                <Footer/>

            </div>
          </div>
        </>
    );
}

export default HomeScreen;