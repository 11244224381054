import React from "react";
import { Footer } from "../../components";

const NotFoundScreen = () => {
  return (
    <div className="font-inter antialiased bg-dark dark:bg-dark text-gray-100 tracking-tight">
      <section className="relative h-screen flex items-center justify-center bg-gradient-to-r from-black via-blue-600 to-yellow-700 text-white">
        <div className="absolute inset-0 z-0">
          {/* Background Animation for 'Lost in Space' Effect */}
          <div className="absolute w-full h-full bg-cover bg-center animate-pulse opacity-30"
               style={{ backgroundImage: "url('/path-to-your-space-image.jpg')" }} />
          <div className="absolute w-full h-full bg-black opacity-60"></div>
        </div>

        <div className="max-w-6xl mx-auto px-4 sm:px-6 z-10 relative">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20 text-center">
            <div className="max-w-3xl mx-auto">
              
              <h1 className="text-5xl font-bold mb-8 text-white tracking-wide leading-tight">
                Whoops! We couldn’t find that page 🪐
              </h1>

              <p className="text-xl mb-8 text-gray-100">
                It seems like this page took a detour in the galaxy. But don’t worry, we’ll get you back on track in no time!
              </p>

              <a
                className="btn text-white bg-blue-500 hover:bg-blue-400 inline-flex items-center mx-auto px-8 py-3 rounded-full text-lg transition-all duration-300"
                href="/"
              >
                <span>Back to Earth</span>
                <svg className="w-4 h-4 shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default NotFoundScreen;
