import React from "react"

export const TrustedBy = () => {
    return(
        <>
            <section 
                id="trustedby"
                className="section flex flex-col items-center py-12 bg-white relative"
            >
                <img
                    src={require('../../assets/images/trusted_by_background_image.png')}
                    alt="Background"
                    className="absolute top-0 left-0 w-full h-full object-cover object-center "
                />
                
                <div className="w-full overflow-hidden relative">
                    <h1 className="text-4xl md:text-4xl text-navy text-center mb-8 px-4">
                        <b>Trusted</b> by <b></b> landlords
                    </h1>
                </div>
                
                <div className="w-full overflow-hidden relative">
                    <div className="image-slider flex space-x-4 sm:space-x-6 md:space-x-8 animate-slideRight">
                        {[...Array(12)].map((_, index) => (
                            <div
                                key={index}
                                className="w-14 h-14 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-cover bg-center aspect-[1/1]"
                                style={{
                                    backgroundImage: `url(${require(`../../assets/images/landlords/landlord_${index + 1}.png`)})`,
                                }}
                            ></div>
                        ))}
                        {/* Duplicate the images to ensure infinite loop */}
                        {[...Array(12)].map((_, index) => (
                            <div
                                key={index + 12}
                                className="w-14 h-14 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-cover bg-center aspect-[1/1]"
                                style={{
                                    backgroundImage: `url(${require(`../../assets/images/landlords/landlord_${index + 1}.png`)})`,
                                }}
                            ></div>
                        ))}
                    </div>
                </div>
                
                {/* Slider - Slide Left */}
                <div className="w-full overflow-hidden relative my-20 md:my-40">
                    <div className="image-slider flex space-x-4 sm:space-x-6 md:space-x-8 animate-slideLeft">
                        {[...Array(12)].map((_, index) => (
                            <div
                                key={index + 12}
                                className="w-14 h-14 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-cover bg-center aspect-[1/1]"
                                style={{
                                    backgroundImage: `url(${require(`../../assets/images/landlords/landlord_${index + 6}.png`)})`,
                                }}
                            ></div>
                        ))}
                        {/* Duplicate the images to ensure infinite loop */}
                        {[...Array(12)].map((_, index) => (
                            <div
                                key={index + 24}
                                className="w-14 h-14 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-cover bg-center aspect-[1/1]"
                                style={{
                                    backgroundImage: `url(${require(`../../assets/images/landlords/landlord_${index + 6}.png`)})`,
                                }}
                            ></div>
                        ))}
                    </div>
                </div>

            </section>
        </>
    )
}