import React, { useState } from "react";
import packages from './packages.json';

export const Pricing = () => {
    const [isAnnually, setIsAnnually] = useState<boolean>(false);
    
    return(
        <>
            {/* Pricing */}
            <section 
                    id="pricing"
                    className="flex flex-col items-center py-12 bg-white relative"
                >
                    <img
                        src={require('../../assets/images/pricing_background_image.png')}
                        alt="Background"
                        className="absolute top-0 left-0 w-full h-full object-cover object-center "
                    />

                    <div className="w-full relative px-8 md:px-16">
                        {/* Section Header */}
                        <div className="text-center mb-12">
                            <h1 className="text-4xl md:text-5xl text-navy mb-4 font-semibold">
                                <b>Choose the plan that’s right for you</b>
                            </h1>
                            <p className="text-base md:text-lg text-navy">
                                Take <b>control</b> of your rental properties with ease.
                            </p>
                        </div>

                        {/* Pricing Toggle (Monthly / Annually) */}
                        <div className="flex flex-col items-center mb-4">
                            {/* Toggle Slider Container */}
                            <div className="relative w-72 h-12 bg-gray-200 rounded-full flex items-center">
                                {/* Sliding Indicator */}
                                <div
                                    className={`absolute top-0 bottom-0 my-1 left-0 h-10 w-1/2 bg-white rounded-full transition-transform duration-300 ${
                                        isAnnually ? 'transform translate-x-full' : ''
                                    }`}
                                ></div>

                                {/* Monthly Button */}
                                <button
                                    onClick={() => setIsAnnually(false)}
                                    className={`z-10 w-1/2 h-full rounded-full flex items-center justify-center text-lg font-semibold ${
                                        !isAnnually ? 'text-navy' : 'text-gray-400'
                                    }`}
                                >
                                    Monthly
                                </button>

                                {/* Annually Button */}
                                <button
                                    onClick={() => setIsAnnually(true)}
                                    className={`z-10 w-1/2 h-full rounded-full flex items-center justify-center text-lg font-semibold ${
                                        isAnnually ? 'text-navy' : 'text-gray-400'
                                    }`}
                                >
                                    Annually
                                </button>
                            </div>

                            {/* Save 30% Message */}
                            <div className="h-8 flex items-center justify-center my-4">
                                {isAnnually && (
                                    <div className="px-4 py-2 bg-green-100 text-green-800 text-sm font-semibold rounded-full shadow">
                                        Save 30% when billed annually!
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Pricing Plans */}
                        <div className="flex flex-col sm:flex-row justify-center gap-6 sm:gap-4">

                            {packages.map((pkg) => {
                                
                                const bgColorClass = pkg.id === "Starter" ? 'bg-primary' : pkg.id === "Business" ? 'bg-blue-400' : 'bg-yellow-400';

                                return (
                                    <div key={pkg.id} className="w-full md:w-[400px] bg-white rounded-lg shadow-lg hover:shadow-xl transition-all  flex flex-col">
                                        <div className={`${bgColorClass} text-white text-center p-4 rounded-t-lg`}>
                                            <h2 className="text-2xl font-semibold">{pkg.id}</h2>
                                        </div>                                       
                                        <div className="px-6 flex-grow">
                                            <div className="text-left py-1 pb-4">
                                                <h1 className="text-2xl text-navy font-extrabold py-1">{pkg.id}</h1>
                                                <h3 className="text-3xl text-navy font-bold"> {pkg.id === "Custom" ? '-' : `£${isAnnually ? pkg.price_annual : pkg.price_monthly}`}</h3>
                                                <p className="text-base text-gray-400 pt-2">per account, billed <b>{isAnnually ? 'Annually' : 'Monthly'}</b></p>
                                            </div>
                                            <div className="border-t border-gray-300 py-4">
                                                <ul className=" text-gray-700 space-y-3">
                                                    {pkg.accessed_features.map((item) => (
                                                        <li key={item} className="flex items-center">
                                                            <span>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="49"
                                                                    height="36"
                                                                    viewBox="0 0 36 36"
                                                                    className=" text-primary"
                                                                >
                                                                    <path
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        d="M9 18l4 4 8-8"
                                                                    />
                                                                </svg>
                                                            </span> 
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-center items-center my-6">
                                            <a href={pkg.id === "Custom" ? "mailto:enterprise@rentee.com.au" : "/"} className="bg-white px-16 py-2 border w-full mx-4 border-gray-200 shadow-sm rounded-full hover:bg-gray-100 text-base text-center transition duration-300">
                                                {pkg.id === "Custom" ? "Contact Us" : "Choose"}
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </section>
        </>
    );
}