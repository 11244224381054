import React, { useState } from "react"

export const FAQs = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);
    
    const toggleAccordion = (index: any) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };
    
    return (
        <>
            <section id="faq" className="section py-16 px-8 pb-24 bg-white text-black">
                    <div className="text-center mb-12">
                        <h1 className="text-4xl md:text-5xl text-navy font-semibold mb-4">
                            Frequently Asked <b>Questions</b>
                        </h1>
                        <p className="text-lg md:text-xl text-gray-600">
                            Here are the most common questions landlords have when using our platform.
                        </p>
                    </div>

                    <div className="max-w-4xl mx-auto space-y-6">
                        {/* Accordion Item 1 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(1)}
                            >
                                How do I track rent payments?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 1 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 1 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                You can easily track rent payments through your dashboard. Payments will be added once a tenant notifies us that they have paid their rent. You will be alerted to confirm the payment, and once confirmed, the rent status will be updated. 
                            </div>
                        </div>

                        {/* Accordion Item 2 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(2)}
                            >
                                How do I handle late payments?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 2 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 2 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                If a tenant misses a payment, we will send them reminders until they confirm the payment. Once the tenant updates their payment status, or if they have already paid you directly, you can update their payment status accordingly.
                            </div>
                        </div>

                        {/* Accordion Item 3 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(3)}
                            >
                                Can I add multiple properties to my account?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 3 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 3 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                Subject to the number of properties included in your subscription plan, we offer a Starter Package, which covers up to 5 properties, and a Premium Plan, which covers up to 20 properties. If you have more properties, we recommend contacting us to create a custom package tailored to your needs.
                            </div>
                        </div>

                        {/* Accordion Item 4 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(4)}
                            >
                                How do maintenance requests work?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8  transition-transform duration-300 ${activeAccordion === 4 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 4 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                Tenants can create maintenance requests and upload photos as proof of the issue. Once the request is submitted, we will notify you about the issue from the respective property. You can then address the issue and, once resolved, close it in the maintenance section of the app.
                            </div>
                        </div>

                        {/* Accordion Item 5 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(5)}
                            >
                                <p>How can I communicate with tenants?</p>
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 5 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 5 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                You can easily communicate with tenants through the built-in messaging feature on the platform, ensuring that all conversations are documented for future reference and facilitating better communication. Additionally, you can send mass messages to all your tenants at once, ideal for sharing important information in case of an emergency or for any other relevant updates.
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )
}