import React from "react"
import { FaShieldAlt, FaWrench } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { IoChatbubblesSharp, IoHappy, IoLaptop, IoLogoAndroid, IoLogoApple, IoNotifications } from "react-icons/io5";

export const Features = () => {
    return(
        <>
            <section 
                id="features"
                className="section py-16 px-8 md:px-16 bg-white text-center relative"
            >
                <div className="text-center mb-12">
                    <h1 className="text-4xl md:text-5xl font-extrabold text-navy mb-4">
                        Amazing <span className="text-teal-500">Features</span>
                    </h1>
                    <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
                        We provide top-tier services to help you manage your rental properties seamlessly. Here’s why you should choose us:
                    </p>
                </div>

                {/* Features Section */}
                <div className="flex flex-wrap gap-12 justify-center">

                    {/* Feature 1 - Secure */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex justify-center items-center text-4xl mb-4">
                            <FaShieldAlt />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Secure</h3>
                        <p className="text-lg">
                            We secure your data, it is our top priority always!
                        </p>
                    </div>

                    {/* Feature 2 - Rent Management */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-purple-400 via-purple-500 to-purple-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex justify-center items-center text-4xl mb-4">
                            <AiFillHome />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Rent Management</h3>
                        <p className="text-lg">
                            Easily track and manage rent payments, due dates, and reminders for tenants. Set up automated rent reminders and payment tracking to ensure smooth cash flow management.
                        </p>
                    </div>

                    {/* Feature 3 - Maintenance Requests */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-indigo-400 via-indigo-500 to-indigo-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex justify-center items-center text-4xl mb-4">
                            <FaWrench />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Maintenance Requests</h3>
                        <p className="text-lg">
                            Tenants can quickly submit maintenance requests, which are automatically logged and tracked. Landlords can respond and update the status, ensuring issues are resolved efficiently.
                        </p>
                    </div>

                    {/* Feature 4 - Multi-Messaging */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex justify-center items-center text-4xl mb-4">
                            <IoChatbubblesSharp />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Multi-Messaging</h3>
                        <p className="text-lg">
                            Built-in multi-channel messaging between tenants and landlords, enabling seamless communication across properties and tenants with in-app messages.
                        </p>
                    </div>

                    {/* Feature 5 - Tenant Portal */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex flex-row justify-center items-center text-4xl mb-4">
                            <IoLogoApple className="mx-1"/>
                            <IoLogoAndroid className="mx-1" />
                            <IoLaptop className="mx-1" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Tenant Portal</h3>
                        <p className="text-lg">
                            Gives your tenants a user-friendly app to view payment history, submit maintenance requests, sign agreements, and communicate with landlords.
                        </p>
                    </div>

                    {/* Feature 6 - Lease Renewal Reminders */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-orange-400 via-red-500 to-pink-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex justify-center items-center text-4xl mb-4">
                            <IoNotifications />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Lease Renewal Reminders</h3>
                        <p className="text-lg">
                            We set up automated alerts for lease renewals so you never miss an opportunity to renew or re-sign agreements with tenants.
                        </p>
                    </div>

                    {/* Feature 7 - Easy */}
                    <div className="w-full md:w-1/2 lg:w-1/3 bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 text-white border border-gray-100 rounded-xl p-8 hover:shadow-lg transition duration-300 transform hover:rotate-3 hover:scale-105">
                        <div className="flex justify-center items-center text-4xl mb-4">
                            <IoHappy />
                        </div>
                        <h3 className="text-2xl font-semibold mb-4">Easy</h3>
                        <p className="text-lg">
                            Effortless to use, with a user-friendly interface designed for simplicity.
                        </p>
                    </div>

                </div>
            </section>
        </>
    )
}